<template>
  <div class="box">
    <div :style="backgroundStyle" :id="info.folderId"></div>
    <div class="title" :title="info.folderName">{{ info.folderName }}</div>
  </div>
</template>

<script>
export default {
  name: "folderBox",
  props: {
    info: Object,
  },
  data() {
    return {
      backgroundStyle: {
        backgroundImage: `url(${require('@/assets/file/wjj.png')})`,
        width: "100%",
        height: "100%",
        backgroundSize: 'cover', // 适应容器大小
        backgroundPosition: 'center' // 居中对齐
      }
    }
  }
}
</script>


<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  .title {
    z-index: 900;
    width: 100%;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;       /* 禁止换行 */
    overflow: hidden;          /* 隐藏超出内容 */
    text-overflow: ellipsis;   /* 使用省略号替代被隐藏的文本 */
  }
}
.box:hover{
  .title {
    color: #29AAE3;
    text-decoration: underline;
  }
}
</style>